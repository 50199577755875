import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import {
    TextField,
    createFilterOptions, 
  } from "@mui/material";

const _ = require('lodash');

export function MultiSelectAutoCompleteProps(value) {
    //Returns props for autocomplete component when multi-select is required
    return {
        multiple: true,
        disableCloseOnSelect: true,
        defaultValue: [],
        value: !Array.isArray(value) || typeof value === 'string'
        ? []
        : value
    }
}

export function SingleSelectAutoCompleteProps(value) {
    //Returns props for autocomplete component when single-select is required
    return {
        defaultValue: null,
        value: Array.isArray(value) ?
        value[0] : value  //To handle transitions between multi and single select 
        || null,
    }
}

export function AutoCompleteCommonProps(handleOnChange, values) {
    //Returns common props for autocomplete component
    function getRenderOptions(renderOptionProps, option, { inputValue }) {
        const matches = match(option.label, inputValue, {
        insideWords: true,
        });
        const parts = parse(option.label, matches);

        const getFontWeight = (part) => { return part.highlight ? 700 : 400 }

        return (
        <li {...renderOptionProps}>
            <div>
            {parts.map((part, index) => (
                <span
                key={index}
                style={{
                    fontWeight: getFontWeight(part)
                }}
                >
                {part.text}
                </span>
            ))}
            </div>
        </li>
        );
    }

    function handleIsOptionEqualToValue(option, selectedValue) {
        if (selectedValue === null || selectedValue === undefined || selectedValue === '') {
        return false;
        }
        return _.isEqual(selectedValue, option);
    }

    function handleGetOptionLabel(option) {
        //Display the label of the selected option in autocomplete
        return option !== "" && option !== null && option.label !== undefined
        ? option.label
        : "";
    }

    return {
        size: "small",
        sx: { width: 0.7 },
        options: values,
        filterOptions: createFilterOptions({
        matchFrom: "any",
        limit: 500,
        }),
        getOptionLabel: (option) => handleGetOptionLabel(option),
        loading: values.length === 0,
        onChange: (event, newValue) => {
        handleOnChange(newValue);
        },
        isOptionEqualToValue: (option, selectedValue) => handleIsOptionEqualToValue(option, selectedValue),
        renderInput: (params) => <TextField {...params} />,
        renderOption: (renderOptionProps, option, inputValue) => { return getRenderOptions(renderOptionProps, option, inputValue); }
    }
}