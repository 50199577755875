import { useState, useEffect } from "react";

import { 
  GetTrackRecordList, 
  SaveTrackRecord, 
  NewTrackRecord,
  deleteTrackRecord
} from "./../API/TrackRecord";

function useManageTrackRecord(trackRecordJson, trackRecordSql, setTrackRecordJson, setViewDataGrid, appBusinessUnit, aggregate, setAggregate) {
  const [savedTrackRecordsList, setSavedTrackRecordsList] = useState([]);
  const [selectedTrackRecord, setSelectedTrackRecord] = useState({});
  const [selectedTrackRecordName, setSelectedTrackRecordName] = useState(null);
  const [trackRecordName, setTrackRecordName] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [focused, setFocused] = useState(false);
  const [color, setColor] = useState("primary");
  const [waitForSave, setWaitForSave] = useState(false);
  const [searchHidden, setSearchHidden] = useState(false);
  const [nameHidden, setNameHidden] = useState(true);

  useEffect(() => {
    TRListOnSelectionChange(null);
    async function getTrackRecords() {
      setSavedTrackRecordsList(await GetTrackRecordList(appBusinessUnit));
    }
    getTrackRecords();
  }, [appBusinessUnit]);

  function toggleFields(hideSearch){  //default: show search field, hide name field
    setSearchHidden(hideSearch);
    setNameHidden(!hideSearch);
  }

  function TRListOnSelectionChange(value) {
    if (value !== null) {
      setViewDataGrid(false);
      setSelectedTrackRecord(value);
      setSelectedTrackRecordName(value);
      setTrackRecordName(value.trackRecordName);
      setTrackRecordJson(value.trackRecordJson);
      setAggregate(value.trackRecordAggregateBy);
    } else {
      setViewDataGrid(false);
      setSelectedTrackRecord(NewTrackRecord(appBusinessUnit));
      setSelectedTrackRecordName(null);
      setTrackRecordName(NewTrackRecord(appBusinessUnit).trackRecordName);
      setTrackRecordJson(NewTrackRecord(appBusinessUnit).trackRecordJson);
      setAggregate([]);
    }
  }

  function TRNameOnChange(event) {
    const {
      target: { value },
    } = event;
    setTrackRecordName(value);
    setError(false);
    setHelperText("");
  }

  function TRNameReset() {
    setError(false);
    setHelperText("")
    setFocused(false);
    setColor("primary");
  }

  async function updateTrackRecord(selectedTR, tRName, tRJson, tRSql, tRBu, tRAggregate) {
    selectedTR.trackRecordName = tRName;
    selectedTR.trackRecordJson = JSON.parse(tRJson);
    selectedTR.trackRecordSql = tRSql;
    selectedTR.appBusinessUnit = tRBu;
    selectedTR.trackRecordAggregateBy = tRAggregate;
    setSavedTrackRecordsList(oldTrackRecords => 
      oldTrackRecords.map(item => 
        item.trackRecordId === selectedTR.trackRecordId 
        ? { ...selectedTR } 
        : item));
    setSelectedTrackRecordName(selectedTR);
    setTrackRecordName(tRName);
    SaveTrackRecord(selectedTR);
  }

  async function createTrackRecord(tRName, tRJson, tRSql, tRBu, tRAggregate) {
    let newTrackRecord = NewTrackRecord(null);
    newTrackRecord.trackRecordName = tRName;
    newTrackRecord.trackRecordJson = JSON.parse(tRJson);
    newTrackRecord.trackRecordSql = tRSql;
    newTrackRecord.appBusinessUnit = tRBu;
    newTrackRecord.trackRecordAggregateBy = tRAggregate;

    const response = await SaveTrackRecord(newTrackRecord);
    const response_json = await response.json();
    newTrackRecord.trackRecordId = response_json.Query_Id;

    setSavedTrackRecordsList(oldTrackRecords => [...oldTrackRecords, newTrackRecord]);
    setTrackRecordName(tRName);
    setSelectedTrackRecordName(newTrackRecord);
    setSelectedTrackRecord(newTrackRecord);
  }

  async function saveOnClick() {
    if(savedTrackRecordsList.some(s => s.trackRecordName.trim().toLowerCase() === trackRecordName.trim().toLowerCase() 
      && (
        selectedTrackRecord == null
        || trackRecordName.trim().toLowerCase() !== selectedTrackRecord.trackRecordName.trim().toLowerCase()
      )
    )) {
      setError(true);
      setHelperText("Track Record already exists.");
      return;
    }
    
    setWaitForSave(true);
    setError(false);
    setHelperText("");

    if (selectedTrackRecord != null && selectedTrackRecord.trackRecordId !== null) {
      await updateTrackRecord(selectedTrackRecord, trackRecordName, trackRecordJson, trackRecordSql, appBusinessUnit, aggregate);
    } 
    else {
      await createTrackRecord(trackRecordName, trackRecordJson, trackRecordSql, appBusinessUnit, aggregate)
    } 
    setFocused(true);
    setWaitForSave(false);
    toggleFields(false);
  }

  function changesPending() {
    const hasValidSelectedTrackRecord = selectedTrackRecord !== null && selectedTrackRecord.trackRecordId !== null;
    const hasNameChanged = selectedTrackRecord?.trackRecordName.trim() !== trackRecordName.trim();
    const hasJsonChanged = JSON.stringify(selectedTrackRecord?.trackRecordJson) !== JSON.stringify(JSON.parse(trackRecordJson));
    const hasDifferentAggregate = selectedTrackRecord?.trackRecordAggregateBy.length !== aggregate.length 
                                || selectedTrackRecord?.trackRecordAggregateBy.some((value, index) => value !== aggregate[index]);
    const isCreatingNewRecord = selectedTrackRecord === null && trackRecordName.trim() !== "";

    return ((hasValidSelectedTrackRecord && (hasNameChanged || hasJsonChanged || hasDifferentAggregate)) || isCreatingNewRecord);
  }

  function addOnClick() {
    setSelectedTrackRecordName(null);
    setSelectedTrackRecord(null);
    setTrackRecordName("");
    setError(false);
    setHelperText("");
  }

  function editOnClick() {
    toggleFields(true);
    setTrackRecordName(selectedTrackRecord.trackRecordName); 
  } 

  function clearTRName() {
    setError(false);
    setHelperText("");
    if(selectedTrackRecord !== null && selectedTrackRecord.trackRecordId !== null)
      setTrackRecordName(selectedTrackRecord.trackRecordName); 
    else
      setTrackRecordName("");
  }

  function clearTRQuery() {
    console.log(selectedTrackRecord);
    if(selectedTrackRecord !== null && selectedTrackRecord.trackRecordId !== null)
    {
      setTrackRecordJson(selectedTrackRecord.trackRecordJson);
      setAggregate(selectedTrackRecord.trackRecordAggregateBy);
    }
  }

  function getUniqueCloneName() {
    let cloneName = `${selectedTrackRecordName.trackRecordName.trim()} - Copy`;
    let existingNames = savedTrackRecordsList.map(record => record.trackRecordName);

    if (!existingNames.includes(cloneName)) 
      return cloneName;

    let index = 2;
    while (existingNames.includes(`${cloneName} (${index})`)) {
      index++;
    }
    return `${cloneName} (${index})`;
}

  function cloneOnClick() {
    setError(false);
    setHelperText("");
    toggleFields(false);
    setTrackRecordName(getUniqueCloneName());
  }
  
  async function cloneTrackRecord() {
    if (trackRecordName.trim() === "") {
      setError(true);
      setHelperText("Track Record Name cannot be empty");
      return;
    } 

    if(savedTrackRecordsList.some(s => s.trackRecordName.trim().toLowerCase() === trackRecordName.trim().toLowerCase() 
    )) {
      setError(true);
      setHelperText("Track Record already exists.");
      return;
    }
    
    setWaitForSave(true);
    setError(false);
    setHelperText("");

    await createTrackRecord(trackRecordName, trackRecordJson, trackRecordSql, appBusinessUnit, aggregate)

    setFocused(true);
    setWaitForSave(false);
    toggleFields(false);
  }

  async function deleteOnClick() {
    setWaitForSave(true);
    setError(false);
    setHelperText("");

    await deleteTrackRecord(selectedTrackRecord);
    setSavedTrackRecordsList(oldTrackRecords => 
      oldTrackRecords.filter(item => 
        item.trackRecordId !== selectedTrackRecord.trackRecordId));
    setSelectedTrackRecordName(null);
    setSelectedTrackRecord(null);
    setTrackRecordName("");
    
    setWaitForSave(false);
    toggleFields(false);
  }
  
  return {
    savedTrackRecordsList,
    selectedTrackRecordName,
    TRListOnSelectionChange,
    TRNameOnChange,
    TRNameReset,
    clearTRName,
    clearTRQuery,
    saveOnClick,
    addOnClick,
    editOnClick,
    cloneOnClick,
    cloneTrackRecord,
    deleteOnClick,
    error,
    helperText,
    waitForSave,
    changesPending,
    focused,
    color,
    searchHidden, 
    nameHidden, 
    toggleFields,
    trackRecordName,
    setTrackRecordName
  };
}

export default useManageTrackRecord;