import { useState } from "react";
import { Divider, Stack, Tooltip, Button, Popover, Typography, TextField, Box, CircularProgress } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';

function ActionButtons({ 
  nameHidden, 
  selectedTrackRecordName, 
  trackRecordName, 
  setTrackRecordName, 
  toggleFields, 
  TRNameReset,
  error,
  helperText,
  changesPending,
  trackRecordLoading,
  actions,
}) {
  const { addOnClick, saveOnClick, cloneOnClick, cloneTrackRecord, deleteOnClick, clearTRQuery } = actions;
  
  const popoverOrigins = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    }
  };

  const [clonePopupAnchor, setClonePopupAnchor] = useState(null);
  const [deletePopupAnchor, setDeletePopupAnchor] = useState(null);

  function popoverContent(text, action, onConfirm) {
    return (
      <>
        <Typography sx={{ p: 1, textAlign: "center" }}>
          {text}
        </Typography>
        { action === "clone"
          ? (<TextField
            fullWidth
            sx={{ padding: "0 5px" }}
            size="small"
            value={trackRecordName}
            error={error}
            helperText={helperText}
            onChange={(e) => setTrackRecordName(e.target.value)}
          />) : null 
        }
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', p: 1 }}>
          <Button 
            variant="contained" 
            onClick={() => { 
              onConfirm(); 
            }}
            data-testid={`${action}-confirm-button`}
            onMouseLeave={action === "clone" ? TRNameReset : null}
          >
            Confirm
          </Button>
          <Button 
            onClick={ 
              action === "clone" 
              ? () => { 
                setClonePopupAnchor(null); 
                setTrackRecordName(selectedTrackRecordName.trackRecordName);
              } 
              : () => setDeletePopupAnchor(null)
            }
            data-testid={`${action}-cancel-button`}
          >
            Cancel
          </Button>
        </Box>
      </>
    );
  }

  const cloneConfirmOnClick = () => {
    cloneTrackRecord();
    setClonePopupAnchor(false);
  }

  const deleteConfirmOnClick = () => {
    deleteOnClick();
    setDeletePopupAnchor(false);
  }

  return(
    <Stack
      className = "track-record-action-buttons"
      direction="row"
      sx={{
        width: '100%',
      }}
    >
      {trackRecordLoading ? (
        <CircularProgress />
      ) : (
      <>
      <Tooltip title="Add New Track Record">
        <span>
          <Button
            data-testid="add-button"
            disabled={!nameHidden && !selectedTrackRecordName}
            sx={{ minWidth: 0 }}
            onClick={() => {
              toggleFields(true);
              addOnClick();
            }}
          >
            <AddCircleOutlineIcon fontSize="small"/>
          </Button>
        </span>
      </Tooltip>

      <Tooltip title="Clone Track Record">
        <span>
          <Button
            data-testid="clone-button"
            disabled={!selectedTrackRecordName}
            sx={{ minWidth: 0 }}
            onClick={(event) => {
              setClonePopupAnchor(event.currentTarget);
              cloneOnClick();
            }}>
            <ContentCopyRoundedIcon fontSize="small"/>
          </Button>
        </span>
      </Tooltip>

      {selectedTrackRecordName && (
        <Popover 
          open={Boolean(clonePopupAnchor)}
          anchorEl={clonePopupAnchor}
          onClose={() => {
            setClonePopupAnchor(null);
            setTrackRecordName(selectedTrackRecordName.trackRecordName);
          }} 
          {...popoverOrigins}
          sx={{ '& .MuiPopover-paper': { minWidth: '250px' } }}
        >
          {popoverContent("Save Track Record with name:", "clone", cloneConfirmOnClick)}
        </Popover> 
      )}

      <Divider orientation="vertical" flexItem 
        sx={{ 
          mx: 0.5, 
          my: 'auto', 
          backgroundColor: 'grey.500', 
          height: '70%' 
        }} 
      />

      <Tooltip title="Save Changes to Track Record">
        <span>
          <Button
            data-testid="save-button"
            disabled={!changesPending()} 
            sx={{ minWidth: 0 }}
            onClick={() => {
              saveOnClick();
            }}
          >
            <SaveRoundedIcon fontSize="small"/>
          </Button>
        </span>
      </Tooltip>

      <Tooltip title="Discard Changes">
        <span>
          <Button
            data-testid="clear-button"
            disabled={!changesPending()} 
            sx={{ minWidth: 0 }}
            onClick={() => {
              clearTRQuery();
            }}
            size="small"
          >
            <CloseIcon /> 
          </Button>
        </span>
      </Tooltip>

      <Divider orientation="vertical" flexItem 
        sx={{ 
          mx: 0.5, 
          my: 'auto', 
          backgroundColor: 'grey.500', 
          height: '70%' 
        }} 
      />

      <Tooltip title="Delete Track Record">
        <span>
          <Button
            data-testid="delete-button"
            disabled={!selectedTrackRecordName}
            sx={{ minWidth: 0 }}
            color="error"
            onClick={(event) => {
              toggleFields(false);
              setDeletePopupAnchor(event.currentTarget);
              setTrackRecordName(selectedTrackRecordName.trackRecordName);
            }}>
            <DeleteOutlineIcon fontSize="small" />
          </Button>
        </span>
      </Tooltip>
      {selectedTrackRecordName && (
        <Popover 
          open={Boolean(deletePopupAnchor)}
          anchorEl={deletePopupAnchor}
          onClose={() => setDeletePopupAnchor(null)} 
          {...popoverOrigins}
          sx={{ '& .MuiPopover-paper': { minWidth: '200px' } }}
        >
          {popoverContent(`Delete ${selectedTrackRecordName.trackRecordName}?`, "delete", deleteConfirmOnClick)}
        </Popover> 
      )}
    </>
    )}
    </Stack>
  )
}

export default ActionButtons;