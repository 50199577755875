import { Button, Card } from "@mui/material";
import { Box } from "@mui/system";
import { useOktaAuth } from '@okta/okta-react';

function Login() {
  const { oktaAuth } = useOktaAuth();
  async function handleLogin() {
    await oktaAuth.signInWithRedirect();
  }
  return (
    <Box
      sx={{
        p: 2,
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Card elevation={8} sx={{
        width: 300,
        height: 150,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Button
          variant="contained"
          color="primary" sx={{
            textTransform: 'none'
          }}
          onClick={() => { return handleLogin(); }}
        >
          Login using Bain Capital SSO
        </Button>
      </Card>
    </Box >
  );
}

export default Login;