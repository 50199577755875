//React
import { useState, useEffect } from "react";

//MUI
import {
  Button, Grid, Autocomplete, TextField, Stack
} from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";

//Query Builder
import { ExtendedQueryBuilder, extendedFormatQuery } from "./QueryBuilder/ExtendedQueryBuilder";

//Backend APIs
import { GetAggregateData } from "../API/TrackRecord";

let abortController = null;

function calculateCorrelationToken(correlation_token, sql_query, startDate, endDate) {
  if (correlation_token !== null) {
    if (correlation_token["query"] !== sql_query || correlation_token["startDate"] !== startDate.toISOString() || correlation_token["endDate"] !== endDate.toISOString()) {
      sessionStorage.removeItem("correlation_token");
      return null;
    } else {
      return correlation_token["id"];
    }
  }
}

function TrackRecordBuilder(props) {
  const {
    query, setQuery, setLoader, setColumns, setRows, setRowCount, setViewDataGrid,
    setCorrelationIdTracker, appBusinessUnit, aggregateByFields, fields, setPnlTip, setExpoTip,
    startDate, endDate, isDateRangeValid, aggregate, setAggregate
  } = props;
  const [cancelBtnVisible, setCancelBtnVisible] = useState(false);
  const [runBtnVisible, setRunBtnVisible] = useState(true);
  const [selectedTimePrd, setSelectedTimePrd] = useState('')
  const [isQueryValid, setIsQueryValid] = useState(false);

  useEffect(() => {
    let disabledlist = [];

    if (selectedTimePrd !== '') {
      disabledlist = aggTimePeriods.filter((str) => str !== selectedTimePrd)
    }
    setOptions(options.filter(item => !disabledlist.includes(item.label)))
  }, [selectedTimePrd]
  )

  useEffect(() => {
    setOptions(aggregateByFields)
  }, [aggregateByFields]
  )
  let aggTimePeriods = aggregateByFields.filter((item) => item.category === "Time Period").map((item) => item.label);

  const [options, setOptions] = useState([]);

  function getColumns(columnKs) {
    return columnKs
      .filter((val) => val !== "internalId")
      .map((value) => ({
        field: value,
        headerName: value,
        width: 200,
        editable: false,
      }));
  }

  async function cancel() {
    console.log("Operation canceled by the user.");
    abortController.abort();
    setLoader(false);
    setRunBtnVisible(true);
    setCancelBtnVisible(false);
  }

  async function onRunClick(sql_query, aggregateby) {
    abortController = new AbortController();
    setRowCount(0);
    setRows([]);
    setColumns([]);
    setLoader(true);
    setRunBtnVisible(false);
    setCancelBtnVisible(true);
    setViewDataGrid(true);
    console.log("Query from UI:" + JSON.stringify(sql_query));
    let query_resp;
    const aggregate_by = JSON.stringify(aggregateby);
    let correlation_token = JSON.parse(
      sessionStorage.getItem("correlation_token")
    );

    try {
      let correlation_token_id = calculateCorrelationToken(
        correlation_token,
        sql_query,
        startDate,
        endDate
      );

      query_resp = await GetAggregateData(
        sql_query,
        startDate,
        endDate,
        appBusinessUnit,
        correlation_token_id,
        aggregate_by,
        abortController
      );
    } catch (error) {
      console.log(error);
      setLoader(false);
      setRunBtnVisible(true);
      setCancelBtnVisible(false);
      return;
    }
    const data = query_resp.data;
    correlation_token = query_resp.correlation_token;
    const row_count = query_resp.row_count;
    const pnl_message = query_resp.pnl_message;
    const expo_message = query_resp.expo_message;
    setRowCount(row_count)
    setPnlTip(pnl_message)
    setExpoTip(expo_message)

    setCorrelationIdTracker(
      query_resp.aggregated_file_token === null ? correlation_token : query_resp.aggregated_file_token
    )
    sessionStorage.setItem(
      "correlation_token",
      JSON.stringify({ id: correlation_token, query: sql_query, startDate: startDate, endDate: endDate })
    );
    const finaldata = data;
    if (finaldata.length > 0) {
      const columnKs = Object.keys(finaldata[0]);
      const cols = getColumns(columnKs);
      setColumns(cols);
      setRows(finaldata);
      setLoader(false);
    } else {
      setLoader(false);
    }

    setRunBtnVisible(true);
    setCancelBtnVisible(false);
  }

  return (
    <Stack sx={{ width: 1 }} direction='row' spacing={1}>
      <Grid container sx={{ width: 1 }}>
        <Grid item xs={12} sx={{ marginBottom: 1 }}>
          <ExtendedQueryBuilder {...{ fields, query, setQuery, setIsQueryValid }} />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            multiple
            loading={aggregateByFields.length === 0}
            options={options}
            getOptionLabel={(option) => option.label}
            value={aggregate}
            renderInput={
              (params) => <TextField {...params} label="Group Data By" />
            }
            groupBy={(option) => option.category}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            onChange={(event, newValue) => {
              setAggregate(newValue)
              let latestTimePrds = newValue.map((item) => item.label).filter(item => aggTimePeriods.includes(item))
              if (latestTimePrds.length === 0) {
                setSelectedTimePrd('')
                setOptions(aggregateByFields)
              } else { setSelectedTimePrd(latestTimePrds[0]) }
            }}
          />
        </Grid>
      </Grid >
      <Grid>
        {runBtnVisible && (
          <Button
            id="RunButton"
            aria-label="Run Track Record Query"
            variant="contained"
            disabled={!isQueryValid || !isDateRangeValid}
            onClick={() => {
              onRunClick(extendedFormatQuery(query, 'SQL'), aggregate.map((item) => item.label));
            }}
          >
            <PlayArrowRoundedIcon />
          </Button>
        )}

        {cancelBtnVisible && (
          <Button
            id="CancelButton"
            variant="contained"
            onClick={() => {
              cancel();
            }}
          >
            <StopRoundedIcon />
          </Button>
        )}
      </Grid >
    </Stack>
  )
}

export default TrackRecordBuilder;