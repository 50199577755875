import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#2ec5d3',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				contained: {
					textTransform: "none",
					color: "#fff",
					backgroundColor: "#1976d2",
					":hover": {
						backgroundColor: "#1976d2",
					},
				},
			},
		},
	}
});

export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#2ec5d3',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				contained: {
					textTransform: "none",
					color: "#fff",
					backgroundColor: "#1976d2",
					":hover": {
						backgroundColor: "#1976d2",
					},
				},
			},
		},
	}
});