import { DateField, LocalizationProvider, CalendarIcon } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  isFuture,
  isValid,
  parse as parseDate,
  parseISO,
  startOfDay
} from "date-fns";
import { Box, Grid, IconButton, Popover, Stack, Tab, Tabs, TextField } from "@mui/material";
import PropTypes from "prop-types";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { CREDIT_MIN_DATE, PE_MIN_DATE } from '../utils/constants';


export function RangePicker(props) {
  const { startDate, setStartDate, endDate, setEndDate, setIsDateRangeValid, appBusinessUnit } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const minDate = appBusinessUnit === "Credit" ? CREDIT_MIN_DATE : PE_MIN_DATE;

  const validateDateRange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setIsDateRangeValid(newStartDate && newEndDate && newStartDate <= newEndDate && newStartDate >= minDate);
  }

  const handleFutureDate = (date) => {
    if (isFuture(date)) {
      return startOfDay(new Date());
    }
    return date;
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDailyRangeChange = (dates) => {
    const [start, end] = dates;
    validateDateRange([start, end]);
  };

  const handleMonthRangeChange = (dates) => {
    const [start, end] = dates;
    let endDt = end && endOfMonth(end);
    endDt = handleFutureDate(endDt);

    validateDateRange([start, endDt]);
  };

  const handleQuarterRangeChange = (dates) => {
    const [start, end] = dates;
    let endDt = end && endOfQuarter(end);
    endDt = handleFutureDate(endDt);

    validateDateRange([start, endDt]);
  };

  const handleYearRangeChange = (dates) => {
    const [start, end] = dates;
    let endDt = end && endOfYear(end);
    endDt = handleFutureDate(endDt);

    validateDateRange([start, endDt]);
  };

  const handleStartDateChange = (date) => {
    const start = parseDate(date, 'yyyy-MM-dd', new Date());
    if (isValid(start)) {
      validateDateRange([handleFutureDate(start), endDate]);
    }
  }

  const handleEndDateChange = (date) => {
    const end = parseDate(date, 'yyyy-MM-dd', new Date());
    if (isValid(end)) {
      validateDateRange([startDate, handleFutureDate(end)]);
    }
  }

  function CustomTabPanel(tabPanelProps) {
    const { children, value: tabValueProp, index, ...other } = tabPanelProps;

    return (
      <div
        role="tabpanel"
        hidden={tabValueProp !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabValueProp === index && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 1 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const datePickerCommonProps = {
    disabledKeyboardNavigation: true,
    dateFormat: "yyyy-MM-dd",
    selectsRange: true,
    showTimeSelect: false,
    inline: true,
    minDate: minDate,
    maxDate: new Date(),
    parseInputDates: parseISO,
    monthsShown: 1,
    fixedHeight: true,
    startDate: startDate,
    endDate: endDate
  }

  const dateFieldCommonProps = {
    size: "small",
    style: { width: 120 },
    format: "yyyy-MM-dd",
    clearable: true,
    required: true
  }


  return (
    <div data-testid='range-picker'>
      <Stack direction="row" maxWidth="false" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateField
            {...dateFieldCommonProps}
            label="Start Date"
            value={startDate}
            onBlur={(newValue) => handleStartDateChange(newValue.target.value)}
            renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} error />}
            error={(startDate > endDate) || (startDate < minDate) || !isValid(startDate)}
          />
          <HorizontalRuleRoundedIcon />
          <DateField
            {...dateFieldCommonProps}
            label="End Date"
            value={endDate}
            onBlur={(newValue) => handleEndDateChange(newValue.target.value)}
            renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} error />}
            error={(startDate > endDate) || !isValid(endDate)}
          />
        </LocalizationProvider>
        <IconButton color="primary" size="small" onClick={(event) => {
          setOpen(true); setAnchorEl(event.currentTarget)
        }
        }>
          <CalendarIcon />
        </IconButton>
      </Stack>

      <Popover open={open}
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null); setOpen(false) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Date Range picker"
            >
              <Tab label="Daily" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="Monthly" {...a11yProps(1)} sx={{ textTransform: "none" }} />
              <Tab label="Quarterly" {...a11yProps(2)} sx={{ textTransform: "none" }} />
              <Tab label="Yearly" {...a11yProps(3)} sx={{ textTransform: "none" }} />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleDailyRangeChange}
                showYearDropdown
                showMonthDropdown
                monthsShown={2}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleMonthRangeChange}
                showMonthYearPicker
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleQuarterRangeChange}
                showQuarterYearPicker
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleYearRangeChange}
                showYearPicker
              />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}