import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const checkboxStyle = { 
  marginRight: 8 
};

const filterByNameStart = (options, inputValue) => 
options !== undefined 
&& options.filter((option) => 
  option.name !== '' 
  && option.name.toLowerCase().startsWith(inputValue.toLowerCase())
);

const filterByNameInclude = (options, inputValue) => 
options.filter((option) => 
  option.name.toLowerCase().includes(inputValue.toLowerCase())
);

const allFilteredSelected = (filteredArray, selectedArray) => 
filteredArray.every(obj1 => 
  selectedArray.some(obj2 => 
    Object.keys(obj1).every(key => obj1[key] === obj2[key])
  )
);

const subtractNewSelection = (prevSelected, newOptions) => 
prevSelected.filter(objA => 
  !newOptions.some(objB => 
    objB.name === objA.name
  )
); 

// Combine arrays and remove duplicates: 
const addNewSelection = (prevSelected, newOptions) => 
[...prevSelected, ...newOptions].filter((option, index, self) =>
  index === self.findIndex((t) => (
      t.name === option.name
  ))
);

const isSelectAllOption = (option) => 
  option.label.includes('Select All');

const CustomAutocomplete = ({ handleChangeQueryBuilder, ...props }) => {
  const { options: allOptions, value } = props;

  const [filterValue, setFilterValue] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    // Check Select all if all filtered options already selected:
    if(allFilteredSelected(filterByNameStart(allOptions, filterValue), value)) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [value, filterValue, allOptions]);
  
  const handleInputChange = (event, newFilterValue) => {
    setFilterValue(newFilterValue);
  }

  const handleChange = (event, newValue) => {
    if (!newValue.some(isSelectAllOption)) {
      handleChangeQueryBuilder(newValue);
      return;
    }

    // Select All option:
    let newSelectedOptions, newOptions = filterByNameStart(allOptions, filterValue);

    if(allFilteredSelected(newOptions, value)) 
      newSelectedOptions = subtractNewSelection(value, newOptions); // unselecting
    else
      newSelectedOptions = addNewSelection(value, newOptions);
    
    handleChangeQueryBuilder(newSelectedOptions);
    setFilterValue("");
  }

  const customFilter = (options, params) => { 
    // Inject the Select All option:
    let selectAllLabel = params.inputValue !== '' ? `Select All Starting With "${params.inputValue}"` : 'Select All';
    let filtered = filterByNameInclude(options, params.inputValue);
    return [{ label: selectAllLabel }, ...filtered]
  }
  
  return (
    <Autocomplete
      data-testid='custom-autocomplete'
      {...props}
      renderOption={(renderOptionProps, option, { selected }) => ( 
        <li {...renderOptionProps}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={checkboxStyle}
            checked={
              isSelectAllOption(option)   
              ? selectAllChecked
              : selected
            }
          />
          {option.label}
        </li>
      )}
      onChange={handleChange}
      onInputChange={handleInputChange}  
      filterOptions={customFilter}
    />
  );
}
  
export default CustomAutocomplete;
  