import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Divider,
  ListItemIcon,
  Container,
  Box,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  Switch
} from "@mui/material";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import SwitchAccessShortcutRoundedIcon from '@mui/icons-material/SwitchAccessShortcutRounded';
import Logout from '@mui/icons-material/Logout';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useOktaAuth } from '@okta/okta-react';
import { darkTheme, lightTheme } from "../styles/Theme";
import "../App.css";
import { ThemeContext } from "../App";
import { getConfig } from "../config";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

let appConfig = await getConfig();

function useHeader() {
  const { setTheme } = useContext(ThemeContext);
  const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem('isDarkTheme') === "true");
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
              setUserInfo(info);
          });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (isDarkTheme) {
      setTheme(darkTheme);
    } else {
      setTheme(lightTheme);
    }
  }, [isDarkTheme, setTheme]);

  async function handleLogOut() {
    await oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + '/login'
    });
  }

  function handleThemeToggle(event) {
    const isChecked = event.target.checked;
    setIsDarkTheme(isChecked);
    localStorage.setItem('isDarkTheme', isChecked);
  }

  return { userInfo, authState, isDarkTheme, handleLogOut, handleThemeToggle };
}

function handleBUSelector(powerUser, selectBusinessUnit) {
  if (powerUser) {
    return (
        <MenuItem>
          <ListItemIcon>
            <Tooltip title="Switch Business Unit" arrow>
              <SwitchAccessShortcutRoundedIcon fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          {selectBusinessUnit}
        </MenuItem>
    );
  } else {
    return null;
  }
}

function handleBusinessUnits(userInfo, setAppBusinessUnit, setUserBU) {
  let updatedBU = false;
  for (let item of appConfig.USER_BU_TO_APP_BU_MAP) {
    if (userInfo.workday_finance_bu.includes(item.user_bu)) {
      setAppBusinessUnit(item.default_app_bu);
      updatedBU = true;
      break;
    }
  }
  if (!updatedBU){
    setAppBusinessUnit("Unsupported")
  }
  setUserBU(userInfo.workday_finance_bu);
}

function Header({ appBusinessUnit, setAppBusinessUnit, userBU, setUserBU }){

  const { userInfo, authState, isDarkTheme,
    handleLogOut, handleThemeToggle } = useHeader();

  let powerUser = false;
  for (let item of appConfig.CROSS_BU_USER_GROUPS) {
    if (userInfo && userInfo.user_groups !== undefined && userInfo.user_groups.includes(item)) {
      powerUser = true;
      break;
    }
  }


  useEffect(() => {
    if (userInfo && appBusinessUnit === ""){
      handleBusinessUnits(userInfo, setAppBusinessUnit, setUserBU)
    }
  }, [userInfo]);


  const handleBUChange = (event) =>{
    setAppBusinessUnit(event.target.value)
    console.log(`Setting App Business Unit to: ${event.target.value}`)
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let selectBusinessUnit =
      <FormControl fullWidth>
        <TextField
          select
          label="Switch App Business Unit"
          onChange={handleBUChange}
          value={appBusinessUnit}
        >
          {appConfig.BUSINESS_UNITS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
          ))}
        </TextField>
      </FormControl>


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#000270" }}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Box component="img" src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="logo" sx={{ width: 40, height: 40, display: "flex", mr: 1 }} />
            <Tooltip title="iPA" placement="bottom" arrow>
              <Typography
                variant="h6"
                nowrap="true"
                sx={{
                  mr: 2,
                  display: "flex",
                }}
              >
                Investment Performance
              </Typography>
            </Tooltip>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} >
              {
                (authState?.isAuthenticated) && <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    color: "#fff"
                  }}
                  onClick={handleClick}
                >
                  {userInfo ? userInfo.name : <CircularProgress />}
                </Button>
              }
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                Dark Theme <Switch
                  checked={isDarkTheme}
                  onChange={(event) => handleThemeToggle(event)}
                />
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <EmailOutlinedIcon fontSize="small" />
                </ListItemIcon>
                {userInfo ? userInfo.email : ""}
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <Tooltip title="User Business Unit" arrow>
                    <BusinessRoundedIcon fontSize="small" />
                  </Tooltip>
                </ListItemIcon>
                {userBU}
              </MenuItem>
              <Divider />
              {handleBUSelector(powerUser, selectBusinessUnit)}
              <Divider />
              <MenuItem onClick={() => { return handleLogOut(); }}>
                <ListItemIcon>
                  <Logout
                    fontSize="small"
                  />
                </ListItemIcon>
                Logout
              </MenuItem>

            </Menu>

          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
}

export default Header;