import React, {Component, useState, createContext, useMemo} from "react";
import { BrowserRouter as Router, Route, withRouter, useHistory } from "react-router-dom";
import {CssBaseline, ThemeProvider, Typography} from "@mui/material";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import {LoginCallback, Security, SecureRoute} from "@okta/okta-react";
import { getConfig } from "./config";
import { TrackRecordsContainer } from "./components/TrackRecordsContainer";
import Login from "./components/Login";
import Header from "./components/Header";
import { lightTheme } from "./styles/Theme";
import {GetAggregateByFields, GetFieldsWithValues} from "./API/TrackRecord";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";


let appConfig = await getConfig();
export const ThemeContext = createContext();

function AppComponent() {
  const history = useHistory();

  const oktaAuth = new OktaAuth({
    issuer: appConfig.OKTA_ISSUER,
    clientId: appConfig.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    responseType: 'id_token',
    scopes: ['openid', 'profile', 'groups', 'email']
  });

  const [appBusinessUnit, setAppBusinessUnit] = useState("");
  const [userBU, setUserBU] = useState(null);

  function fetchAllData(){
    const promise1 = GetAggregateByFields(appBusinessUnit);
    const promise2 = GetFieldsWithValues(appBusinessUnit);

    return Promise.all([promise1, promise2])
  }

  function displayError(message){
    return (
        <Alert severity={"error"} variant={"filled"}>
          <AlertTitle>Error</AlertTitle>
          {message}
        </Alert>
    );
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };

  const [theme, setTheme] = useState(lightTheme);
  const [newContainer, setNewContainer] = useState(null);

  useMemo(()=> {
    if (appBusinessUnit && appConfig.BUSINESS_UNITS.includes(appBusinessUnit)) {

      // Display a loader until we get the data from the below API Calls for new BU
      setNewContainer(<div className="App-body"><CircularProgress size={60}/></div>)

      fetchAllData().then(([aggData, fieldData]) => {
        setNewContainer(
            <TrackRecordsContainer appBusinessUnit={appBusinessUnit} aggData={aggData} fieldData={fieldData}/>
        )
      }).catch(error => {
        if (error.message && error.message.includes("NetworkError")) {
          setNewContainer(displayError("Backend currently unavailable. Please try again later."))
        } else {
          setNewContainer(displayError(
              `An unhandled exception occurred on the backend. Please contact App Support: ${error.message}`
          ))
        }

      })
    }else if ( appBusinessUnit && appBusinessUnit === "Unsupported" ){
      setNewContainer(
          <div className="App-body">
            <Typography variant="h4">
              Unsupported Business Unit. Please select a valid Business Unit.
            </Typography>
          </div>
      )
    }
  }, [appBusinessUnit])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Header
                appBusinessUnit={appBusinessUnit} setAppBusinessUnit={setAppBusinessUnit}
                userBU={userBU} setUserBU={setUserBU}
            />
            <Route path="/login" exact={true} component={Login} />
              <SecureRoute path="/" exact={true} render={() => (newContainer) }/>
            <Route path="/login/callback" component={LoginCallback} />
          </Security>
        </div >
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

const AppWithRouterAccess = withRouter(AppComponent);

class App extends Component {
  render() {
    return (<Router><AppWithRouterAccess /></Router>);
  }
}

export default App;
