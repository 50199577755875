import { getConfig } from "../config";
import { defaultOperators } from "react-querybuilder";
import { format } from "date-fns";

let appConfig = await getConfig();

export function TrackRecordModel(appBusinessUnit){
  let trackRecordJson = null;
  if (appBusinessUnit && appBusinessUnit === "Credit"){
    trackRecordJson = appConfig.CREDIT_INITIAL_QUERY
  }else {
    trackRecordJson = appConfig.PE_INITIAL_QUERY
  }
  return {
    trackRecordId: null,
    trackRecordName: "",
    trackRecordJson: trackRecordJson,
    trackRecordSql: "(1 = 1)",
    trackRecordAggregateBy: [],
    appBusinessUnit: ""
  }
}

export function NewTrackRecord(appBusinessUnit) {
  return { ...TrackRecordModel(appBusinessUnit) };
}

export async function GetTrackRecordList(appBusinessUnit) {
  try {
    let url = appConfig.API_BASE_URL + "/queries?";
    const params = new URLSearchParams({
      business_unit: appBusinessUnit
    });
    const response = await fetch(url + params, {
      method: "get",
    });
    const data = await response.json();

    return data.map((trackRecord) => {
      let parsed_trackRecord = { ...TrackRecordModel };
      parsed_trackRecord.trackRecordId = trackRecord.Query_Id;
      parsed_trackRecord.trackRecordName = trackRecord.Query_Name;
      parsed_trackRecord.trackRecordJson = trackRecord.Query_Value;
      parsed_trackRecord.trackRecordSql = trackRecord.Query_Raw_Value;
      parsed_trackRecord.trackRecordAggregateBy = trackRecord.Aggregate_By;

      return parsed_trackRecord;
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function SaveTrackRecord(trackRecord) {
  let url = appConfig.API_BASE_URL + "/queries";
  if (trackRecord.trackRecordId) {
    url = url + "/" + trackRecord.trackRecordId;
  }
  const requestOptions = {
    method: trackRecord.trackRecordId ? "PUT" : "POST", 
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query_name: trackRecord.trackRecordName,
      business_unit: trackRecord.appBusinessUnit,
      query_filter: JSON.stringify(trackRecord.trackRecordJson),
      query_raw_filter: trackRecord.trackRecordSql,
      aggregate_by: JSON.stringify(trackRecord.trackRecordAggregateBy),
      is_deleted: 0
    }),
  };
  try {
    return await fetch(url, requestOptions);
  } catch (error) {
    console.log(error);
    return;
  }
} 

export async function deleteTrackRecord(trackRecord) {
  let url = appConfig.API_BASE_URL + "/queries";
  if (!trackRecord.trackRecordId) return;

  url = url + "/" + trackRecord.trackRecordId;

  const requestOptions = {
    method: "DELETE", 
    headers: { "Content-Type": "application/json" },
  };
  try {
    return await fetch(url, requestOptions);
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function GetAggregateData(
  sql_query,
  start_date,
  end_date,
  business_unit,
  correlation_token_id,
  aggregate_by,
  controller
) {
  let url = appConfig.API_BASE_URL + "/get_aggregate_data?";

  const options = {
    method: "GET",
    signal: controller.signal
  };
  const params = new URLSearchParams({
    sql_query: sql_query,
    start_date: format(start_date, 'yyyy-MM-dd'),
    end_date: format(end_date, 'yyyy-MM-dd'),
    business_unit: business_unit,
    correlation_token: correlation_token_id,
    user_id: 111,
    aggregate_by: aggregate_by,
  });

  const response = await fetch(url + params, options);
  return response.json();
}

function handle_response(response) {
  if (!response.ok) {
      const error = new Error(response.statusText);
      error.statusCode = response.status;
      throw error;
    }
    return response.json();
}

export async function GetFieldsWithValues(appBusinessUnit) {
  let url = appConfig.API_BASE_URL + "/query-builder-fields?";

  const selectedOperators = ["in", "notIn", "contains", "doesNotContain", "doesNotBeginWith", "doesNotEndWith", "beginsWith", "endsWith", "=", "!="];

  const options = {
    method: "GET",
  };

  const params = new URLSearchParams({
    business_unit: appBusinessUnit
  });

  return fetch(url + params, options).then(response => {
    return handle_response(response);
  }).then(data => {
    let result = data.map((g) => {
    return {
      ...g,
      options: g.options.map((i) =>
        i.values === undefined
          ? { ...i, name: `${i.label}` }
          : {
            ...i,
            operators: defaultOperators.filter((op) =>
              selectedOperators.includes(op.name)
            ),
            name: i.name !== undefined ? `${i.name}` : `${i.label}`,
          }
      ),
    };
  });

    result = result.map((i) =>
        i.label === "Time"
            ? {
              ...i,
              options: i.options.map((j) => j.name === "AsOf_Date" || j.name === "As Of Date" ? { ...j, operators: defaultOperators.filter((op) => op.name === "between") } : j)
            }
            : i
    );
    return Promise.resolve(result)
  }).catch(error => {
    return Promise.reject(error)
  })
}

export async function GetAggregateByFields(appBusinessUnit) {
  let url = appConfig.API_BASE_URL + "/aggregateby-fields?";
  const options = {
    method: "GET",
  };
  const params = new URLSearchParams({
    business_unit: appBusinessUnit
  });
  return fetch(url + params, options).then(response => {
    return handle_response(response);
  }).then(data => {
    return Promise.resolve(data);
  }).catch(error => {
    return Promise.reject(error);
  })
}

export async function GetFullDataFile(correlation_token) {
  let url = appConfig.API_BASE_URL + "/get_full_data_file?";
  const params = new URLSearchParams({
    correlation_token: correlation_token,
    user_id: 111
  });
  const options = {
    method: "GET"
  };
  const response = await fetch(url + params, options);
  return response.blob();
}
