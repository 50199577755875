import { useState, useRef } from 'react';
import {
  Stack,
  Autocomplete,
  Tooltip,
  TextField,
  CircularProgress,
  Popover,
  Typography,
  Button,
  Box
} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import useManageTrackRecord from "../../hooks/useManageTrackRecord";
import ActionButtons from "./ActionButtons";

import "../../styles/main.css";

export default function ManageTrackRecord({
  setTrackRecordJson,
  trackRecordJson,
  trackRecordSql,
  setViewDataGrid,
  appBusinessUnit,
  aggregate,
  setAggregate
}) {

  const {
    savedTrackRecordsList,
    selectedTrackRecordName,
    TRListOnSelectionChange,
    TRNameOnChange,
    TRNameReset,
    clearTRName,
    clearTRQuery,
    saveOnClick,
    addOnClick,
    editOnClick,
    cloneOnClick,
    cloneTrackRecord,
    deleteOnClick,
    error,
    helperText,
    waitForSave,
    changesPending,
    focused,
    color,
    searchHidden, 
    nameHidden, 
    toggleFields,
    trackRecordName,
    setTrackRecordName
  } = useManageTrackRecord(trackRecordJson, trackRecordSql, setTrackRecordJson, setViewDataGrid, appBusinessUnit, aggregate, setAggregate);

  const [autocompleteIsHovered, setAutocompleteIsHovered] = useState(false);
  const [autocompleteIsFocused, setAutocompleteIsFocused] = useState(false);
  const [unsavedChangesAnchor, setUnsavedChangesAnchor] = useState(false);
  const [pendingAutocompleteValue, setPendingAutocompleteValue] = useState(false);

  const trackRecordLoading = savedTrackRecordsList.length === 0; 

  const autocompleteRef = useRef(null);

  const handleAutocompleteChange = (event, newValue) => {
    if (changesPending()) {
      setPendingAutocompleteValue(newValue);
      setUnsavedChangesAnchor(autocompleteRef.current);
    } else {
      TRListOnSelectionChange(newValue);
    }
  };

  return (
    <Stack 
      className="manage-track-record"
      data-testid="manage-track-record"
      spacing={1} 
      direction={{ xs: "row", lg:"column"}} 
      sx={{ width: 1 }}
    >
      <Autocomplete
        sx={{ 
          width: 1,
          display: searchHidden ? "none" :  "flex"
        }}
        size="small"
        defaultValue={""}
        loading={trackRecordLoading}
        value={selectedTrackRecordName}
        options={savedTrackRecordsList}
        onChange={(event, value) => {
          handleAutocompleteChange(event, value);
        }}
        getOptionLabel={(option) => {
          return option.trackRecordName !== undefined
            ? option.trackRecordName
            : "";
        }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            onFocus={() => setAutocompleteIsFocused(true)}
            onBlur={() => setAutocompleteIsFocused(false)}
            onMouseEnter={() => setAutocompleteIsHovered(true)}
            onMouseLeave={() => setAutocompleteIsHovered(false)}
            label="Search Track Records" 
            InputProps={{ 
              ...params.InputProps,
              endAdornment: (
                <>
                {params.InputProps.endAdornment}
                {selectedTrackRecordName && (
                  <Tooltip title="Edit Track Record Name">
                    <IconButton
                      data-testid="edit-button"
                      sx={{ 
                        p: '2px',
                        visibility: (autocompleteIsHovered || autocompleteIsFocused) ? 'visible' : 'hidden'
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        editOnClick();
                      }}
                      onMouseDown={(event) => {
                        // Prevent the button action from stealing focus from the input
                        event.preventDefault();
                      }}
                      onMouseLeave={TRNameReset}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                </>
              )
            }}
            inputRef={autocompleteRef}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.trackRecordName, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.trackRecordName, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
      <Popover 
        open={Boolean(unsavedChangesAnchor)}
        anchorEl={unsavedChangesAnchor}
        onClose={() => {
          setUnsavedChangesAnchor(null);
          setPendingAutocompleteValue(null); 
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{ '& .MuiPopover-paper': { minWidth: '200px' } }}
      >
        <Typography sx={{ 
          p: 1, 
          textAlign: "center",
        }}>
          You have unsaved changes. 
          <br />
          Are you sure you want to proceed without saving?
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', p: 1 }}>
          <Button 
            variant="contained" 
            onClick={() => {
              TRListOnSelectionChange(pendingAutocompleteValue);
              setPendingAutocompleteValue(null); 
              setUnsavedChangesAnchor(null);
            }}
          >Confirm</Button>
          <Button 
            onClick={() => {
              setUnsavedChangesAnchor(null);
              setPendingAutocompleteValue(null); 
          }}>Cancel</Button>
        </Box>
      </Popover> 
      <TextField 
        className="track-record-textfield"
        sx={{ 
          width: 1,
          display: nameHidden ? "none" : "flex"
        }}
        size="small"
        label={"Track Record Name"}
        value={trackRecordName}
        error={error}
        helperText={helperText}
        focused={focused}
        color={color}
        onChange={TRNameOnChange}
        InputProps={{
        endAdornment: (
          <>
            {waitForSave 
            ? (<CircularProgress size={20} />)
            : (<>
              <Tooltip title="Discard Changes">
                <IconButton
                  data-testid="clear-button"
                  onClick={() => {
                    clearTRName();
                    toggleFields(false);
                  }}
                  size="small"
                >
                  <CloseIcon fontSize="small" /> 
                </IconButton>
              </Tooltip>
            </>)}
          </>
        ) }}
      />
      <ActionButtons 
        nameHidden={nameHidden}
        selectedTrackRecordName={selectedTrackRecordName}
        trackRecordName={trackRecordName}
        setTrackRecordName={setTrackRecordName}
        toggleFields={toggleFields}
        TRNameReset={TRNameReset}
        error={error}
        helperText={helperText}
        changesPending={changesPending}
        trackRecordLoading={trackRecordLoading}
        actions={{
          addOnClick,
          saveOnClick,
          cloneOnClick,
          cloneTrackRecord,
          deleteOnClick,
          clearTRQuery
        }}
      />
    </Stack>
  );
}
