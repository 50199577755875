//React
import { useState, useEffect, useContext } from "react";

//MUI
import {
  TextField, Autocomplete, Grid,
  Popover, Tabs, Tab, Box, Skeleton, IconButton, Stack
} from "@mui/material";
import { DateField, LocalizationProvider, CalendarIcon } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// MUI component imports
import DragIndicator from "@mui/icons-material/DragIndicator";
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextareaAutosize from "@mui/material/TextareaAutosize";

//Query Builder
import {
  usePrevious, formatQuery, transformQuery, defaultValueProcessorByRule, QueryBuilder
} from "react-querybuilder";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  parseISO, parse as parseDate, endOfMonth, endOfQuarter, endOfYear, startOfDay, isFuture, format, isValid
} from 'date-fns';
import PropTypes from 'prop-types';
import {
  QueryBuilderMaterial,
  MaterialValueEditor,
} from "@react-querybuilder/material";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import "react-querybuilder/dist/query-builder.scss";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeContext } from "../../App";

//Autocomplete
import { MultiSelectAutoCompleteProps, SingleSelectAutoCompleteProps, AutoCompleteCommonProps } from './AutocompleteProps';

import CustomAutocomplete from "./CustomAutocomplete";
import { SELECT_ALL_LIMIT } from "../../utils/constants";

const muiComponents = {
  Button,
  Checkbox,
  DragIndicator,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextareaAutosize,
};
let qbFields = null

//#region Custom Value Editors
function DateWithTimePeriodsValueEditor(dateWithTimePeriodsValueEditorProps) {
  const { value, handleOnChange, anchorEl, setAnchorEl, tabValue, setTabValue } = dateWithTimePeriodsValueEditorProps;

  const open = Boolean(anchorEl);

  const startDatefromValue = value?.length > 0 && value[0] ? new Date(value[0]) : null;
  const endDatefromValue = value?.length > 0 && value[1] ? new Date(value[1]) : null;

  const handleFutureDate = (date) => {
    if (isFuture(date)) {
      return startOfDay(new Date())
    }
    return date
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDailyRangeChange = (dates) => {
    const [start, end] = dates;
    handleOnChange([start, end]);
  };

  const handleMonthRangeChange = (dates) => {
    const [start, end] = dates;
    let endDt = end && endOfMonth(end)
    endDt = handleFutureDate(endDt)

    handleOnChange([start, endDt]);
  };

  const handleQuarterRangeChange = (dates) => {
    const [start, end] = dates;
    let endDt = end && endOfQuarter(end)
    endDt = handleFutureDate(endDt)

    handleOnChange([start, endDt]);
  };

  const handleYearRangeChange = (dates) => {
    const [start, end] = dates;
    let endDt = end && endOfYear(end)
    endDt = handleFutureDate(endDt)

    handleOnChange([start, endDt]);
  };

  const handleStartDateChange = (date) => {
    const startDate = parseDate(date, 'yyyy-MM-dd', new Date());

    if (isValid(startDate)) {
      handleOnChange([handleFutureDate(startDate), endDatefromValue]);
    }
  }

  const handleEndDateChange = (date) => {
    const endDate = parseDate(date, 'yyyy-MM-dd', new Date());

    if (isValid(endDate)) {
      handleOnChange([startDatefromValue, handleFutureDate(endDate)]);
    }
  }

  function CustomTabPanel(tabPanelProps) {
    const { children, value: tabValueProp, index, ...other } = tabPanelProps;

    return (
      <div
        role="tabpanel"
        hidden={tabValueProp !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabValueProp === index && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 1 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const datePickerCommonProps = {
    disabledKeyboardNavigation: true,
    dateFormat: "yyyy-MM-dd",
    selectsRange: true,
    showTimeSelect: false,
    inline: true,
    minDate: new Date('1997-01-01'),
    maxDate: new Date(),
    parseInputDates: parseISO,
    monthsShown: 1,
    fixedHeight: true,
    startDate: startDatefromValue,
    endDate: endDatefromValue
  }

  const dateFieldCommonProps = {
    size: "small",
    style: { width: 120 },
    format: "yyyy-MM-dd",
  }

  return (
    <div>
      <Stack direction="row" maxWidth="false" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateField
            {...dateFieldCommonProps}
            label="Start Date"
            value={startDatefromValue}
            onBlur={(newValue) => handleStartDateChange(newValue.target.value)}
            renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} error />}
            error={startDatefromValue > endDatefromValue}
          />
          <HorizontalRuleRoundedIcon />
          <DateField
            {...dateFieldCommonProps}
            label="End Date"
            value={endDatefromValue}
            onBlur={(newValue) => handleEndDateChange(newValue.target.value)}
            renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} error />}
            error={startDatefromValue > endDatefromValue}
          />
        </LocalizationProvider>
        <IconButton color="primary" size="small" onClick={(event) => { setAnchorEl(event.currentTarget) }}>
          <CalendarIcon />
        </IconButton>
      </Stack>

      <Popover open={open}
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null); }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Date Range picker"
            >
              <Tab label="Daily" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="Monthly" {...a11yProps(1)} sx={{ textTransform: "none" }} />
              <Tab label="Quarterly" {...a11yProps(2)} sx={{ textTransform: "none" }} />
              <Tab label="Yearly" {...a11yProps(3)} sx={{ textTransform: "none" }} />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleDailyRangeChange}
                showYearDropdown
                showMonthDropdown
                monthsShown={2}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleMonthRangeChange}
                showMonthYearPicker
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleQuarterRangeChange}
                showQuarterYearPicker
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <DatePicker
                {...datePickerCommonProps}
                onChange={handleYearRangeChange}
                showYearPicker
              />
            </CustomTabPanel>
          </Grid>
        </Grid >
      </Popover>
    </div >
  );
}

function AutocompleteValueEditor(props) {
  const { value, handleOnChange, operator, values } = props;
  const isAutoCompleteRequired = ['=', '!=', 'in', 'notIn'].includes(operator);

  if (!isAutoCompleteRequired)
    return <MaterialValueEditor {...props} />;

  let autocompleteProps = { ...AutoCompleteCommonProps(handleOnChange, values) };
  if (['in', 'notIn'].includes(operator)) {
    if(values.length <= SELECT_ALL_LIMIT) 
      return (
        <CustomAutocomplete
        { ...({ ...autocompleteProps, ...MultiSelectAutoCompleteProps(value), handleChangeQueryBuilder: handleOnChange })}
        />
      );
    else
      return (
        <Autocomplete
        { ...({ ...autocompleteProps, ...MultiSelectAutoCompleteProps(value), handleOnChange })}
        />
      );
  }

  return (
    <Autocomplete
      {...({ ...autocompleteProps, ...SingleSelectAutoCompleteProps(value) })}
    />
  );
}

const isPlainText = (op) => !['=', '!=', 'in', 'notIn'].includes(op);

const isSingleSelect = (op) => ['=', '!='].includes(op);

const isMultiSelect = (op) => ['in', 'notIn'].includes(op);

const isSameType = (previousOp, currentOp) => 
  (isPlainText(previousOp) && isPlainText(currentOp)) ||
  (isSingleSelect(previousOp) && isSingleSelect(currentOp)) ||
  (isMultiSelect(previousOp) && isMultiSelect(currentOp))

function CustomValueEditor(props) {
  // Scenarios:
  // 1. Changing from and to the same operator group (plain text, single select autocomplete, multi select autocomplete)
  // 2. Changing operator to multi select autocomplete from something else
  // 3. Changing operator to single select autocomplete or plaintext from something else

  const { value, handleOnChange, operator } = props;

  const previousOperator = usePrevious(operator);

  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // To handle plain text default value when a new rule is added:
  if (isSingleSelect(operator) && typeof value === 'string')
    handleOnChange(null);

  useEffect(() => {
    if (previousOperator === null || isSameType(previousOperator, operator)) return;

    if(isMultiSelect(operator)) 
      handleOnChange([]);
    else if (isSingleSelect(operator))
      handleOnChange(null);
    else
      handleOnChange("");
  }, [previousOperator, operator, handleOnChange]);

  if (props.fieldData.inputType === 'autocomplete') {
    return AutocompleteValueEditor(props);
  }
  else if (props.fieldData.inputType === 'datewithtimeperiods') {
    return DateWithTimePeriodsValueEditor({ ...props, anchorEl, setAnchorEl, tabValue, setTabValue });
  }
  else {
    return <MaterialValueEditor {...props} />;
  }
}
//#endregion

//#region Extended Query Builder
export function ExtendedQueryBuilder(props) {
  const { fields, query, setQuery, setIsQueryValid } = { ...props };
  const { theme } = useContext(ThemeContext);
  qbFields = fields;

  const handleQueryChange = (newQuery) => {
    let hasEmptyValue = newQuery.rules.some((r) => r.value === null || r.value === "" || (Array.isArray(r.value) && r.value.length === 0));
    setIsQueryValid(!hasEmptyValue);
    setQuery(newQuery);
  }

  return (
    <>
      {(Array.isArray(fields) && fields.length === 0) ? <Skeleton variant="rectangular" height={41} />
        : <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
          <ThemeProvider theme={theme}>
            <QueryBuilderMaterial muiComponents={muiComponents}>
              <QueryBuilder
                fields={qbFields}
                query={query}
                onQueryChange={handleQueryChange}
                controlClassnames={{
                  queryBuilder: "queryBuilder-branches",
                }}
                controlElements={{
                  valueEditor: CustomValueEditor
                }}
              />
            </QueryBuilderMaterial>
          </ThemeProvider>
        </QueryBuilderDnD>}
    </>
  );
}
//#endregion

//#region Extended Format Query function
function customRuleProcessor(rule) {
  //Function to add inputType prop to the rule object
  return {
    ...rule,
    inputType: qbFields?.flatMap((item) => item.options).find((f) => f.name === rule.field)?.inputType,
  };
}

function autocompleteValueProcessorByRule(rule) {
  if (rule.inputType === 'autocomplete') {
    let processedValues = { ...rule }.value;

    const getSQLClauseValue = (queryObjectValue) => {
      return (queryObjectValue.name === undefined) ? `'${queryObjectValue}'` : `'${queryObjectValue.name}'`;
    }

    if (processedValues !== null) {
      if (typeof processedValues === 'object' && !Array.isArray(processedValues)) {
        return `'${processedValues.name}'`;
      }
      else if (['in', 'notIn'].includes(rule.operator) && Array.isArray(processedValues)) {
        return `(${processedValues.map((i) => getSQLClauseValue(i))})`;
      }
    }
  }
}

function datewithtimeperiodsValueProcessorByRule(rule) {
  if (rule.inputType === 'datewithtimeperiods') {
    let processedValues = { ...rule }.value;

    if (rule.operator === 'between' && processedValues && Array.isArray(processedValues)) {
      return `'${processedValues[0] && format(new Date(processedValues[0]), 'yyyy-MM-dd')}' and '${processedValues[1] && format(new Date(processedValues[1]), 'yyyy-MM-dd')}'`;
    }
  }
}

function customValueProcessorSQL(rule, options) {
  let result = autocompleteValueProcessorByRule(rule);
  if (result !== undefined) {
    return result;
  }

  result = datewithtimeperiodsValueProcessorByRule(rule);
  if (result !== undefined) {
    return result;
  }

  return defaultValueProcessorByRule(rule, options);
}

export function extendedFormatQuery(query, outputFormat = 'JSON') {
  if (outputFormat === 'SQL') {
    return formatQuery(transformQuery(query, { ruleProcessor: customRuleProcessor }), { format: outputFormat, valueProcessor: customValueProcessorSQL, quoteFieldNamesWith: ['[', ']'] });
  }
  else if (outputFormat === 'JSON') {
    return formatQuery(transformQuery(query, { ruleProcessor: customRuleProcessor }), { format: outputFormat });
  }
}
//#endregion